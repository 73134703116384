import {getAccessToken} from "../../Utilities/localstorage/getAccessToken";
import {supabase} from "../../Auth/supabaseClient";

const BASE_URL = "https://rbfdagomafltuzfmfmgk.supabase.co/functions/v1/media"

const mediaService = {
    async deleteImage(id, siteId) {
        const token = getAccessToken();
        try {
            const response = await fetch(`${BASE_URL}?mediaId=${id}&siteId=${siteId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                console.error('Failed to delete image');
            }

            return response;
        } catch (error) {
            console.error('Error deleting image:', error);
            return false;
        }
    },

    async uploadFile(file, siteId) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('siteId', siteId);

        const token = getAccessToken();
        try {
            const response = await fetch(`${BASE_URL}`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                return await response.json();
            } else {
               console.error('Upload failed');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            return null;
        }
    },
    async fetchImages(siteId) {
        try {
            const { data, error } = await supabase
                .from('media')
                .select('*')
                .eq('site_id', siteId);

            if (error) {
                console.error('Error getting images');
            }

            return data;
        } catch (error) {
            console.error('Error fetching images:', error);
            return null;
        }
    }
};

export default mediaService;
