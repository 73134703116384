import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import { useToast } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FiBarChart2 } from "react-icons/fi";
import { FEATURE_FLAGS } from "constants/featureFlags";

import {
  Badge,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Flex,
  Th,
  Td,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Container,
  Text,
  Tooltip,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import {
  FiTrash2,
  FiSearch,
  FiPlus,
  FiEye,
  FiSettings,
  FiBookOpen,
  FiArrowLeft,
} from "react-icons/fi";
import { supabase } from "./Auth/supabaseClient";
import Bar from "./Utilities/WorkspaceBar";
import moment from "moment";
import AddSiteModal from "./Onboarding-site/AddSiteModal";

const Workspace = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sites, setSites] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [versions, setVersions] = useState({});
  const handleSiteAdded = (newSite) => {};
  const [sortColumn, setSortColumn] = useState("lastModified");
  const [sortDirection, setSortDirection] = useState("desc");
  const [showStatistics, setShowStatistics] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [iframeLoading, setIframeLoading] = useState(true);

  const sortedSites = [...sites].sort((a, b) => {
    if (sortColumn === "name") {
      return sortDirection === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    }
   
    if (sortColumn === "lastModified") {
      return sortDirection === "asc"
        ? new Date(a.last_modified) - new Date(b.last_modified)
        : new Date(b.last_modified) - new Date(a.last_modified);
    }
    return 0;
  });

  useEffect(() => {
    const fetchSites = async () => {
      const { data: sites, error } = await supabase
        .from("site")
        .select("*")
        .order("Date", { ascending: false });

      if (error) {
        console.error("Error fetching sites:", error);
      } else {
        setSites(sites);
      }
    };

    fetchSites();

    const sitesChannel = supabase
      .channel("realtime:public:site")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "site" },
        async (payload) => {
          const newSite = payload.new;
          setSites((prevSites) => [newSite, ...prevSites]);
        }
      )
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "site" },
        (payload) => {
          const updatedSite = payload.new;
          setSites((prevSites) =>
            prevSites.map((site) =>
              site.id === updatedSite.id ? updatedSite : site
            )
          );
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(sitesChannel);
    };
  }, []);

  useEffect(() => {
    const fetchVersions = async () => {
      const { data: versions, error } = await supabase
        .from("page_versions")
        .select("version, id, site_id")
        .order("version", { ascending: false });

      if (error) {
        console.error("Error fetching versions", error);
        return;
      }

      const versionsBySiteId = {};
      versions.forEach((version) => {
        const { site_id } = version;
        if (!versionsBySiteId[site_id]) {
          versionsBySiteId[site_id] = [];
        }
        versionsBySiteId[site_id].push(version);
      });

      setVersions(versionsBySiteId);
    };

    fetchVersions();

    const versionsChannel = supabase
      .channel("realtime:public:page_versions")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "page_versions" },
        async (payload) => {
          const newVersion = payload.new;
          setVersions((prevVersions) => {
            const { site_id } = newVersion;
            if (!prevVersions[site_id]) {
              prevVersions[site_id] = [];
            }
            return {
              ...prevVersions,
              [site_id]: [...prevVersions[site_id], newVersion],
            };
          });
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(versionsChannel);
    };
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSites = sites.filter(
    (site) =>
      site &&
      site.id &&
      site.name &&
      site.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  const handleShowStatistics = (siteId) => {
    setSelectedSiteId(siteId);
    setShowStatistics(true);
  };

  const handleBackToSites = () => {
    setShowStatistics(false);
    setSelectedSiteId(null);
  };

  return (
    <Flex>
      <Bar />
      <Box
        flex={1}
        width={{ base: "100%", md: "calc(100% - 250px)" }}
        ml={{ base: 0, md: "250px" }}
      >
        <Box pl={2} pr={2}>
          <Box
            bg="white"
            borderRadius="lg"
            boxShadow="sm"
            p={6}
            overflowX="auto"
          >
            <Container maxW="container.lg" textAlign="center">
              <Heading as="h1" size="md" mb={2}>
                {showStatistics ? "Site Statistics" : "Your Sites"}
              </Heading>
            </Container>
          </Box>

          <Container maxW="container.xl" mt={8}>
            {showStatistics ? (
              <Box>
                <Button
                  leftIcon={<FiArrowLeft />}
                  colorScheme="brand"
                  size="md"
                  onClick={handleBackToSites}
                  mb={4}
                >
                  Back to Sites
                </Button>
                <Box
                  bg="white"
                  borderRadius="md"
                  boxShadow="md"
                  p={6}
                  position="relative"
                >
                  {iframeLoading && (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      height="500px"
                    >
                      <Box
                        as="img"
                        src="https://siteboon.blob.core.windows.net/images/logo-animated.svg"
                        alt="Loading"
                        width="100px"
                        height="100px"
                      />
                    </Flex>
                  )}
                  <iframe
                    plausible-embed
                    src={`https://plausible.io/share/siteboon.ai?auth=d7DErOvMFcTb2uf6fK4N5&embed=true&theme=light`}
                    scrolling="no"
                    frameBorder="0"
                    loading="lazy"
                    style={{ width: "1px", minWidth: "100%", height: "1500px" }}
                    onLoad={() => setIframeLoading(false)}
                  ></iframe>
                  <div
                    style={{ fontSize: "14px", paddingBottom: "14px" }}
                  ></div>
                  <script
                    async
                    src="https://plausible.io/js/embed.host.js"
                  ></script>
                </Box>
              </Box>
            ) : (
              <>
                <Box
                  mb={4}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <InputGroup maxW="sm">
                    <InputLeftElement pointerEvents="none">
                      <FiSearch color="gray.500" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search sites..."
                      value={searchTerm}
                      onChange={handleSearch}
                      borderColor="gray.300"
                      focusBorderColor="brand.500"
                      rounded="md"
                      size="lg"
                      variant="filled"
                      _placeholder={{ color: "gray.500" }}
                    />
                  </InputGroup>
                  <Button
                    leftIcon={<FiPlus />}
                    colorScheme="brand"
                    size="md"
                    onClick={onOpen}
                  >
                    Add New Site
                  </Button>
                  <AddSiteModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onSiteAdded={handleSiteAdded}
                  />
                </Box>
                {filteredSites.length === 0 ? (
                  <Box
                    bg="white"
                    borderRadius="md"
                    boxShadow="md"
                    p={6}
                    textAlign="center"
                  >
                    <Text fontSize="xl" mb={4}>
                      You don't have any sites yet. Let's create your first
                      site!
                    </Text>
                    <Button
                      leftIcon={<FiPlus />}
                      colorScheme="brand"
                      size="lg"
                      onClick={onOpen}
                    >
                      Add New Site
                    </Button>
                  </Box>
                ) : (
                  <Box
                    bg="white"
                    borderRadius="md"
                    boxShadow="md"
                    p={6}
                    overflowX="auto"
                  >
                    <Table variant="simple" colorScheme="brand">
                      <Thead>
                        <Tr>
                          <Th
                            onClick={() => handleSort("name")}
                            cursor="pointer"
                            _hover={{ bg: "brand.100" }}
                          >
                            Site Name{" "}
                            {sortColumn === "name" &&
                              (sortDirection === "asc" ? "▲" : "▼")}
                          </Th>
                          <Th></Th>
                          <Th>Versions</Th>


                          <Th
                            onClick={() => handleSort("lastModified")}
                            cursor="pointer"
                            _hover={{ bg: "brand.100" }}
                          >
                            Last Modified{" "}
                            {sortColumn === "lastModified" &&
                              (sortDirection === "asc" ? "▲" : "▼")}
                          </Th>
                          <Th>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                      {sortedSites.map((site) => (
                          <SiteRow
                            key={site.id}
                            site={site}
                            versions={versions[site.id] || []}
                            setSites={setSites}
                            onOpen={onOpen}
                            handleShowStatistics={handleShowStatistics}
                          />
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}
              </>
            )}
          </Container>
        </Box>
      </Box>
    </Flex>
  );
};

const SiteRow = ({
  site,
  versions,
  setSites,
  isVisible,
  onOpen,
  handleShowStatistics,
}) => {
  const toast = useToast();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const cancelRef = React.useRef();
  const [confirmationText, setConfirmationText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalInitialStep, setModalInitialStep] = useState(0);
  const [modalInitialSiteVersionId, setModalInitialSiteVersionId] =
    useState("");

  const getVersionCount = () => {
    return versions.length;
  };

  const formatDate = (dateString) => {
    if (dateString === null) {
      return "";
    }
    return moment(dateString).fromNow();
  };

  const getFullTimestamp = (dateString) => {
    return moment(dateString).format("MMMM D, YYYY h:mm:ss A");
  };

  const handleDelete = async () => {
    if (confirmationText === site.name) {
      setIsDeleting(true);

      try {
        const { error } = await supabase
          .from("site")
          .delete()
          .eq("id", site.id);

        if (error) {
          toast({
            title: "Error",
            description: "An error occurred while deleting the site.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Site Deleted",
            description: `Site "${site.name}" has been deleted successfully.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setSites((prevSites) => prevSites.filter((s) => s.id !== site.id));
        }
      } catch (error) {
        console.error("Error deleting site:", error);
        toast({
          title: "Error",
          description: "An error occurred while deleting the site.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        // Handle any network or other errors
      }

      onAlertClose();
      setIsDeleting(false);
    }
  };

  const handleModalOpen = () => {
    setModalInitialStep(site.onboarding_ongoing ? 1 : 0);
    if (site.onboarding_ongoing) {
      const siteVersions = versions.filter(
        (version) => version.site_id === site.id
      );
      const lastVersionId = siteVersions.length > 0 ? siteVersions[0].id : "";
      setModalInitialSiteVersionId(lastVersionId);
    }

    onModalOpen();
  };

  return (
    <Transition in={!isDeleting} timeout={1000}>
      {(exitState) => (
        <Tr
          _hover={{ bg: "gray.50", transition: "background-color 0.2s" }}
          style={{
            transition: "opacity 100ms, background-color 0.2s",
            opacity: exitState === "exiting" || exitState === "exited" ? 0 : 1,
          }}
        >
          <Transition in={true} timeout={1000} appear>
            {(enterState) => (
              <>
                <Td>
                  <Flex alignItems="center">
                    {site.onboarding_ongoing ? (
                      <Button
                        onClick={() => handleModalOpen(site)}
                        variant="link"
                      >
                        {site && site.name}
                      </Button>
                    ) : (
                      <Link to={`/site/${site.id}`}>
                        {site && site.name}
                        {site && site.domain_name
                          ? ` (${site.domain_name})`
                          : ""}
                      </Link>
                    )}
                  </Flex>
                </Td>

                <Td>
                  <Badge
                    colorScheme={site.domain_name ? "secondary" : "yellow"}
                  >
                    {site.domain_name ? "Published" : "Draft"}
                  </Badge>
                </Td>

                <Td>
                  <Text fontWeight="medium">{getVersionCount()}</Text>
                </Td>
                <Td>
                  <Tooltip label={getFullTimestamp(site.last_modified)}>
                    <Text>{formatDate(site.last_modified)}</Text>
                  </Tooltip>
                </Td>
                <Td>
                  {site.onboarding_ongoing ? (
                    <Button
                      onClick={() => handleModalOpen(site)}
                      variant="link"
                      leftIcon={<FiBookOpen />}
                      colorScheme="brand"
                      size="sm"
                      mr={2}
                      as={Link}
                    >
                      Onboarding
                    </Button>
                  ) : (
                    <>
                      <Button
                        as={Link}
                        to={`/site/${site.id}`}
                        leftIcon={<FiEye />}
                        colorScheme="brand"
                        size="sm"
                        mr={2}
                      >
                        View
                      </Button>
                      {FEATURE_FLAGS.ENABLEIMPORT && site.domain_name && (
                        <Button
                          leftIcon={<FiBarChart2 />}
                          variant="ghost"
                          colorScheme="brand"
                          size="sm"
                          mr={2}
                          onClick={() => handleShowStatistics(site.id)}
                        >
                          Statistics
                        </Button>
                      )}{" "}
                    </>
                  )}
                  {/* {site.onboarding_ongoing ? (
                    ""
                  ) : (
                    <Tooltip label="Edit site settings">
                      <Button
                        leftIcon={<FiSettings />}
                        variant="ghost"
                        colorScheme="brand"
                        size="sm"
                        mr={2}
                      >
                        Settings
                      </Button>
                    </Tooltip>
                  )} */}

                  <Tooltip label="Delete site">
                    <Button
                      leftIcon={<FiTrash2 />}
                      variant="ghost"
                      colorScheme="red"
                      size="sm"
                      onClick={onAlertOpen}
                    >
                      Delete
                    </Button>
                  </Tooltip>

                  <AlertDialog
                    isOpen={isAlertOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onAlertClose}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          Delete Site
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          <Text mb={4}>
                            Are you sure you want to delete the site "
                            {site.name}"? This action cannot be undone.
                          </Text>
                          <Text mb={2}>
                            Please type the name of the site to confirm:
                          </Text>
                          <Input
                            value={confirmationText}
                            onChange={(e) =>
                              setConfirmationText(e.target.value)
                            }
                            placeholder="Site name"
                          />
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button ref={cancelRef} onClick={onAlertClose}>
                            Cancel
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={handleDelete}
                            ml={3}
                            isDisabled={confirmationText !== site.name}
                          >
                            Delete
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Td>
                <style>
                  {`
                    tr {
                      transition: opacity 1000ms;
                      opacity: ${exitState === "exiting" || exitState === "exited" ? 0 : 1};
                    }
                  `}
                </style>
                <AddSiteModal
                  initialSiteVersionId={modalInitialSiteVersionId}
                  initialSiteId={site.id}
                  isOpen={isModalOpen}
                  onClose={onModalClose}
                  onSiteAdded={() => {}}
                  initialStep={modalInitialStep}
                />
              </>
            )}
          </Transition>
        </Tr>
      )}
    </Transition>
  );
};
export default Workspace;
const StatisticsModal = ({ isOpen, onClose, siteId }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Site Statistics </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <iframe
            plausible-embed
            src={`https://plausible.io/share/siteboon.ai?auth=d7DErOvMFcTb2uf6fK4N5&embed=true&theme=light`}
            scrolling="no"
            frameBorder="0"
            loading="lazy"
            style={{ width: "1px", minWidth: "100%", height: "1550px" }}
          ></iframe>
          <div style={{ fontSize: "14px", paddingBottom: "14px" }}></div>
          <script async src="https://plausible.io/js/embed.host.js"></script>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
