import React, {useEffect, useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Box,
    Divider,
    Grid,
    GridItem,
    Progress,
    useDisclosure,
    Input,
    Image, Spinner, useToast
} from '@chakra-ui/react';
import imageDefaultPencil from "../assets/svg/image-default-pencil.svg";
import trashCan from "../assets/svg/trash-can.svg";
import imageAdd from "../assets/svg/image-add.svg";
import {truncateString} from "../Utilities/auxiliary/truncateString";

const FileUploadModal = ({ handleAssetSelection, assets, setAssets, uploadFile, isOpen, onOpen, onClose, handleDeleteImage, isImageSelected, setIsImageSelected }) => {
    //const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeTab, setActiveTab] = useState('browse');
    const [usedStorage, setUsedStorage] = useState(0);
    const totalStorage = 100000;
    const [editingIndex, setEditingIndex] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [newFileName, setNewFileName] = useState('');
    const [inputURL, setInputURL] = useState('');
    const [dragging, setDragging] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const MAXIMUM_FILE_SIZE_TO_UPLOAD = 20;
    const toast = useToast()

    useEffect(() => {
        const totalUsedBytes = assets.reduce((total, asset) => total + (asset?.file_size || 0), 0);
        const totalUsedMB = totalUsedBytes / (1024 * 1024);
        setUsedStorage(totalUsedMB?.toFixed(2));
    }, [assets]);

    const handleClose = () => {
        onClose()
    }
    const handleOpen = () => {
        setIsImageSelected(false)
        onOpen()
    }

    const onImageDelete = (id) => {
        setIsLoading(true)
        handleDeleteImage(id).then(res => setIsLoading(false))
    }

    const checkIsNewImageNotExceedAllowedMaximumSize = (file) => {
        return file.size / (1024 * 1024) <= MAXIMUM_FILE_SIZE_TO_UPLOAD;
    };

    const checkIsAvailableToLoadNewImage = (file) => {
        const fileSizeMB = file?.size / (1024 * 1024);
        const newTotalStorage = parseFloat(usedStorage) + fileSizeMB;
        return newTotalStorage < totalStorage;
    }

    const checkFileValidations = (file) => {
        if (!file) return false;
        if (!checkIsNewImageNotExceedAllowedMaximumSize(file)) {
            toast({
                title: "Error",
                description: `The maximum file size allowed is ${MAXIMUM_FILE_SIZE_TO_UPLOAD} megabytes`,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return false;
        }

        if (!checkIsAvailableToLoadNewImage(file)) {
            toast({
                title: "Error",
                description: "Not enough storage available.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return false;
        }

        return true;
    };

    const handleFileChange = (e) => {
        setIsLoading(true);
        const selectedFile = e.target.files[0];

        if (checkFileValidations(selectedFile)) {
            uploadFile(selectedFile)
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    };

    const handleFileDialog = () => {
        document.getElementById('fileInput').click();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        setIsLoading(true);
        const selectedFile = e.dataTransfer.files[0];
        if (checkFileValidations(selectedFile)) {
            uploadFile(selectedFile)
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    };

    const handleSaveFileName = (index) => {
        const updatedAssets = [...assets];
        updatedAssets[index] = {
            ...assets[index],
            file_name: newFileName
        };
        setAssets(updatedAssets);
        setEditingIndex(null);
        setNewFileName('');
    };

    const handleEditFileName = (index) => {
        setEditingIndex(index);
        setNewFileName(assets[index].file_name);
    };

    const handleAddImageURL = () => {
        const asset = {
            src: inputURL
        };
        handleAssetSelection(asset);
        onClose();
    };

    const handleAddImage = () => {
        if (selectedIndex !== null) {
            const selectedAsset = assets[selectedIndex];
            const asset = {
                src: selectedAsset?.blob_url
            };
            handleAssetSelection(asset);
        }
    }

    return (
        <>
            <Button onClick={handleOpen} height="35px" position="absolute" top="3px" right="150px">Add image</Button>
            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent
                    background="#102A43"
                    color="white"
                    borderRadius="lg"
                    width={{ base: "90%", md: "700px" }}
                    maxWidth="700px"
                    maxHeight="80vh"
                    overflowY="auto"
                >
                    <ModalHeader>
                        <Box display="flex" position="relative">
                            <Text
                                cursor="pointer"
                                onClick={() => setActiveTab('browse')}
                                position="relative"
                                p="0 10px"
                            >
                                Browse
                            </Text>
                            <Text
                                cursor="pointer"
                                onClick={() => setActiveTab('url')}
                                position="relative"
                                p="0 10px"
                            >
                                From URL
                            </Text>
                            <Box
                                position="absolute"
                                top="39px"
                                left={activeTab === 'browse' ? '0%' : '12%'}
                                width="80px"
                                height="2px"
                                background="white"
                            />
                        </Box>
                    </ModalHeader>

                    <Divider backgroundColor="gray.600" />

                    <ModalBody>
                        {activeTab === 'browse' ? (
                            <>
                                {usedStorage <= totalStorage && (
                                    <Box
                                        marginTop="10px"
                                        border={`2px dashed ${dragging ? '#67A6FF' : '#4575b5'}`}
                                        borderRadius="10px"
                                        height="120px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        position="relative"
                                        cursor="pointer"
                                        onClick={handleFileDialog}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                    >
                                        <Text textAlign="center" fontSize="lg" marginTop="40px">
                                            {dragging ? "Release to upload" : "Drop files here to upload"}
                                        </Text>
                                        <Box position="absolute" top="10px" left="50%" transform="translateX(-50%)">
                                            <Image
                                                src={imageAdd}
                                                alt="Upload Preview"
                                                height="45px"
                                                draggable="false"
                                            />
                                        </Box>
                                        {/* Hidden input for file selection */}
                                        <input
                                            id="fileInput"
                                            type="file"
                                            style={{display: 'none'}}
                                            onChange={handleFileChange}
                                        />
                                    </Box>
                                ) || (
                                    <Box
                                        marginTop="10px"
                                        border={`2px dashed ${dragging ? '#67A6FF' : '#4575b5'}`}
                                        borderRadius="10px"
                                        height="120px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        position="relative"
                                        cursor="not-allowed"
                                    >
                                        <Text textAlign="center" fontSize="lg" color="red">Maximum storage limit reached</Text>
                                    </Box>
                                )
                                }
                                <Box mt={4} display="flex" alignItems="center" justifyContent="space-between">
                                    <Text>Previously Uploaded assets</Text>
                                    <Box md={2} display="flex" flexDirection="column">
                                        <Progress value={(usedStorage / totalStorage) * 100} colorScheme="blue" height="10px" />
                                        <Text fontSize="14px">{usedStorage} MB of {totalStorage} MB used</Text>
                                    </Box>
                                </Box>

                                {/* Display uploaded files */}
                                <Grid
                                    templateColumns="repeat(3, 1fr)"
                                    gap={{ md: "36px 34px" }}
                                    mt={4} padding="76px 54px"
                                    backgroundColor="#0000004D"
                                    overflow="auto"
                                    height="400px"
                                >
                                    {assets.map((asset, index) => (
                                        <GridItem key={asset.id}>
                                            <Box
                                                width="150px"
                                                border={selectedIndex === index ? '1px solid #67A6FF' : ''}
                                                onClick={() => setSelectedIndex(index)}
                                            >
                                                <Image
                                                    src={asset.blob_url}
                                                    alt={asset.file_name}
                                                    width="150px"
                                                    height="120px"
                                                    objectFit="cover"
                                                    draggable="false"
                                                    opacity={isLoading ? 0.4 : 1}
                                                />
                                                {isLoading && (
                                                    <Box
                                                        position="absolute"
                                                        top="0"
                                                        left="0"
                                                        width="100%"
                                                        height="100%"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        background="rgba(0, 0, 0, 0.5)"
                                                    >
                                                        <Spinner size="lg" color="white" />
                                                    </Box>
                                                )}
                                                {selectedIndex === index && (
                                                    <Box
                                                        backgroundColor="#0000004D"
                                                        fontWeight="500px"
                                                        padding="4px"
                                                        width="100%"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        {(selectedIndex === editingIndex) && (index === editingIndex) ?
                                                            <Input
                                                                height="22px"
                                                                flex='0 0 65%'
                                                                padding="0"
                                                                value={newFileName}
                                                                onChange={(e) => setNewFileName(e.target.value)}
                                                                onBlur={() => handleSaveFileName(index)}
                                                                background="#0000004D"
                                                                border="none"
                                                                borderRadius="0"
                                                            />
                                                            :
                                                            <Text color="white" onDoubleClick={() => handleEditFileName(index)}>
                                                                {truncateString(asset.file_name)}
                                                            </Text>
                                                        }
                                                        <Box display="flex" alignItems="flex-start" flex='0 0 30%'>
                                                            <Image
                                                                src={imageDefaultPencil}
                                                                alt='Edit'
                                                                width="22px"
                                                                marginRight="4px"
                                                                cursor="pointer"
                                                                onClick={() => handleEditFileName(index)}
                                                            />
                                                            <Image
                                                                src={trashCan}
                                                                alt='Edit'
                                                                width="20px"
                                                                cursor="pointer"
                                                                onClick={() => onImageDelete(asset?.id)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        </GridItem>
                                    ))}
                                </Grid>
                            </>
                        ) : (
                            <Box display="flex" alignItems="center" mt={4}>
                                <Input
                                    value={inputURL}
                                    onChange={(e) => setInputURL(e.target.value)}
                                    placeholder="Enter image URL"
                                    background="#2D3748"
                                    border="1px solid #FFFFFF"
                                    borderRadius="10px"
                                    mr={2}
                                />
                                <Button
                                    background="#3C8DFF"
                                    color="white"
                                    borderRadius="12px"
                                    width={{ base: "100%", md: "119px" }}
                                    onClick={handleAddImageURL}
                                    isDisabled={!inputURL}
                                >
                                    {isImageSelected ? "Replace image" : "Add Image"}
                                </Button>
                            </Box>
                        )}
                    </ModalBody>
                    <ModalFooter justifyContent="center">
                        {activeTab === 'browse' &&
                            <Button
                                background="#3C8DFF"
                                color="white"
                                borderRadius="12px"
                                onClick={handleAddImage}
                                isDisabled={selectedIndex === null}
                            >
                                {isImageSelected ? "Replace image" : "Add Image"}
                            </Button>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default FileUploadModal;
