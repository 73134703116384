import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiCheck, FiClock } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsUnsavedVersionViewing,
  updateSelectedVersionInhistoryPage,
} from "../store/editorSlice";
import { selectedVersionInhistoryPage } from "../store/selectors";
import { useSite } from "./SiteContext";

const VersionSelector = () => {
  const {
    versions,
    currentVersion,
    setCurrentVersion,
    setcurrentPageVersionsId,
  } = useSite();
  const toast = useToast();
  const [expandedVersions, setExpandedVersions] = useState({});
  const versionInHistoryPage = useSelector(selectedVersionInhistoryPage);
  const dispatch = useDispatch();

  useEffect(() => {
    const IsPageLatest =
      versions.findIndex(
        (version) => version.SiteVersionsId === versionInHistoryPage
      ) === 0;
    dispatch(setIsUnsavedVersionViewing(!IsPageLatest));
  }, [versionInHistoryPage]);

  useEffect(() => {
    if (!versionInHistoryPage) handleViewVersion(versions[0]);
  }, []);

  const handleViewVersion = (version) => {
    setCurrentVersion(version.label);
    setcurrentPageVersionsId(version.SiteVersionsId);
    dispatch(updateSelectedVersionInhistoryPage(version.SiteVersionsId));

    toast({
      title: "Version Changed",
      description: `You are now viewing version ${version.label}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const formatChangeDescription = (description) => {
    if (!description) return ["No changes recorded"];
    return description
      .split(";")
      .map((change) => change.trim())
      .filter((change) => change !== "");
  };

  const toggleExpand = (versionId) => {
    setExpandedVersions((prev) => ({
      ...prev,
      [versionId]: !prev[versionId],
    }));
  };

  return (
    <Flex direction="column" height="100%">
      <Box bg="gray.800" p={4} color="white">
        <HStack mb={4}>
          <FiClock size={24} />
          <Text fontSize="lg" fontWeight="bold">
            Version History
          </Text>
        </HStack>
      </Box>
      <VStack
        spacing={4}
        align="stretch"
        overflowY="auto"
        flex={1}
        p={4}
        bg="gray.800"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.500",
            borderRadius: "24px",
          },
        }}
      >
        {versions.map((version, index) => {
          const changes = formatChangeDescription(version.change_description);
          const firstChange = changes[0];
          const restChanges = changes.slice(1);
          const isExpanded = expandedVersions[version.id];

          return (
            <Box
              key={version.id}
              bg="gray.700"
              p={4}
              borderRadius="md"
              borderLeft={
                version.label === currentVersion ? "4px solid" : "none"
              }
              borderColor="teal.500"
              position="relative"
              color="white"
            >
              <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <List spacing={2}>

                <Text fontSize="sm">                        
                    <ListIcon as={FiCheck} color="green.500" />
                {firstChange}
                </Text>
                </List>
                <Badge
                  colorScheme={
                    version.label === currentVersion ? "teal" : "gray"
                  }
                >
                  {version.label === currentVersion
                    ? "Current"
                    : `V${version.label}`}
                </Badge>
              </Flex>
              {restChanges.length > 0 && (
                <Box mb={3}>
                  {isExpanded && (
                    <List spacing={2}>
                      {restChanges.map((change, index) => (
                        <ListItem key={index} fontSize="sm">
                          <ListIcon as={FiCheck} color="green.500" />
                          {change}
                        </ListItem>
                      ))}
                    </List>
                  )}
                  <Link
                    color="teal.300"
                    onClick={() => toggleExpand(version.id)}
                    cursor="pointer"
                    fontSize="sm"
                  >
                    {isExpanded ? "Show less" : "Show more"}
                  </Link>
                </Box>
              )}
              <HStack spacing={2}>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() => handleViewVersion(version)}
                  isDisabled={version.label === currentVersion}
                >
                  View
                </Button>
              </HStack>
            </Box>
          );
        })}
      </VStack>
    </Flex>
  );
};

export default VersionSelector;
